/**
 * Paths for the API
 *
 * @author  Robin Wilding
 */

const isDev = process.env.NODE_ENV === 'development';
const api = isDev ? 'http://localhost:2080/api' : '/api';

export const ApiEndpoint = {

  API: api,

  ANALYTICS: `${api}/analytics`,

  /** @deprecated Only used for testing */
  AUTH: `${api}/auth`,

  AUTH_MAGICLOGIN_SEND: `${api}/auth/magiclogin/send`,
  AUTH_MAGICLOGIN_CALLBACK: `${api}/auth/magiclogin/callback`,
  AUTH_LOGOUT: `${api}/auth/logout`,

  USER: `${api}/user`,
  VERSION: `${api}/version`,

  // Client

  CLIENT_ACCEPTFEEPROPOSAL: `${api}/client/accept-fee-proposal`,

  FILE_LISTUPLOADS: `${api}/storage/list-uploads`,
  FILE_LISTYEAR: `${api}/storage/list-year`,
  FILE_UPLOAD: `${api}/storage/upload`,
  FILE_REMOVE: `${api}/storage/remove`,
  FILE_DOWNLOAD: `${api}/storage/download`,
  FILE_DOWNLOADALL: `${api}/storage/download-all`,
  FILE_DOWNLOADCHECKLIST: `${api}/storage/download-checklist`,
  FILE_NOTIFY: `${api}/storage/notify`,

  DOCUMENTTYPE: `${api}/document-type`,
  DOCUMENTTYPE_LIST: `${api}/document-type/list`,

  // Admin

  ADMIN_UPLOAD: `${api}/admin/upload`,
  ADMIN_CLIENT_LINK: `${api}/admin/client/link`,
  ADMIN_CLIENT_LIST: `${api}/admin/client/list`,
  ADMIN_CLIENT_LIST_SYNC: `${api}/admin/client/list/sync`,
  ADMIN_YEAR: `${api}/admin/year`,
  ADMIN_YEAR_LIST: `${api}/admin/year/list`,
  ADMIN_NOTIFY_CHECKLIST: `${api}/admin/notify/checklist`,
  ADMIN_ANALYTICS_EVENTS: `${api}/admin/analytics/events`,
  ADMIN_ANALYTICS_TYPES: `${api}/admin/analytics/types`,
  ADMIN_INVOICE_LIST: `${api}/admin/invoice/list`,

  // Xero integration

  XERO_CALLBACK: `${api}/xero/callback`,
  XERO_STATUS: `${api}/xero/status`,

  // Guest

  GUEST: (clientGid: number | string, year: string) => `${api}/guest/${clientGid}/${year}`,
  GUEST_PRODUCT_INFO: (productId?: number | string, clientGid?: number | string, year?: string) => `${api}/guest/${clientGid}/${year}/product/${productId}/info`,
  GUEST_PRODUCT_INVOICE: (productId?: number | string, clientGid?: number | string, year?: string) => `${api}/guest/${clientGid}/${year}/product/${productId}/invoice`,
  GUEST_PRODUCT_PDF: (productId?: number | string, clientGid?: number | string, year?: string) => `${api}/guest/${clientGid}/${year}/product/${productId}/pdf`,

};
